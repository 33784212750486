import React from 'react';
import { 
    Box,
    Text, 
    Link, 
    Heading, 
    Button,
    useDisclosure,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerBody,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import styled from 'styled-components';

const Hidden = styled(Box)`
    display: none;

    @media only screen and (max-width: 760px) {
        display: block;
        z-index: 999;
    }
`;

const StyledHeading = styled(Heading)`
  font-size: 0.85rem;
  color: #d7d7d7;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .1rem;
  margin-bottom: 0.5rem;

  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    font-size: 14px;
  }
`;

const MenuItems = (props) => {
    const { children, isLast, to = "/", isExternal, ...rest } = props
    return (
        <Text
            mb={{ base: isLast ? 0 : 0, sm: 0 }}
            mr={{ base: 0, sm: isLast ? 0 : 8 }}
            display="block"
            {...rest}
            fontSize="0.85rem"
            color="#d7d7d7"
        >
            {isExternal ? <Link href={to} isExternal _focus={{ outline: 'none' }}>{children}</Link> : <Link href={to} _focus={{ outline: 'none' }}>{children}</Link>}

        </Text>
    );
}

const MobileMenu = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
            <Hidden>
                <Box as="button" onClick={onOpen}>
                    <HamburgerIcon w={6} h={6} color="white" />
                </Box>
                <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="fullscreen">
                    <DrawerOverlay />
                    <DrawerContent bg="#000">
                    <DrawerCloseButton style={{color: 'white' }} _focus={{ outline: 'none' }} _hover={{ filter: 'brightness(65%)' }} />
                    <DrawerBody>
                        <StyledHeading as="h4" style={{marginTop: '10px'}}>Product</StyledHeading>
                        <MenuItems pb="8px" to="https://app.subseaprotocol.com" isExternal onClick={() => onClose()}>
                            App
                        </MenuItems>

                        <StyledHeading as="h4" style={{marginTop: '30px'}}>Developers</StyledHeading>
                        <MenuItems textColor="white" pb="5px" to="https://docs.subseaprotocol.com" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Documentation</MenuItems>

                        <StyledHeading as="h4" style={{marginTop: '30px'}}>Community</StyledHeading>
                        <MenuItems textColor="white" pb="5px" to="https://twitter.com/SubseaProtocol" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Twitter</MenuItems>
                        <MenuItems textColor="white" pb="5px" to="https://discord.gg/WvW7r6Ekhe" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Discord</MenuItems>
                        <MenuItems textColor="white" pb="5px" to="https://t.me/SubseaProtocol" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Telegram</MenuItems>

                        <StyledHeading as="h4" style={{marginTop: '30px'}}>Foundation</StyledHeading>
                        <RouteLink to="/about">
                            <MenuItems as="a" textColor="white" pb="5px" onClick={() => onClose()}>About</MenuItems>
                        </RouteLink>
                        <MenuItems as="a" textColor="white" pb="5px" to="https://medium.com/@SubseaProtocol" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Blog</MenuItems>

                        <Button
                            mt="30px"
                            as={Link}
                            alignSelf="flex-start"
                            _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
                            _active={{ color: 'transparent' }}
                            _focus={{ outline: 'none', boxShadow: 'none' }}
                            px="var(--button-px)"
                            py="var(--button-py)"
                            textColor="#fafafa"
                            target="_new"
                            background="var(--components-primary-purple)"
                            border="0.2px solid #7E9BD1"
                            borderRadius="5px"
                            height="2.5rem"
                            fontSize="var(--p-font-size)"
                            mb="30px"
                            href="https://app.subseaprotocol.com"
                            onClick={() => onClose()}
                        >
                            Enter App
                        </Button>
                    </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Hidden>
    )
}

export default MobileMenu;