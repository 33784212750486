import React from 'react';
import {
    Text,
    Link,
    Menu,
    MenuButton,
    MenuList,
    useDisclosure,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';

const DisplayMenu = styled.div`
  display: flex;
  align-items: center;
  border-radius: 500px;
  border: 0.5px solid rgba(184, 154, 232, 0.40);
  backdrop-filter: blur(50px);
  padding: 5px 30px;
  column-gap: 24px;
  z-index: 999 !important;

  @media only screen and (max-width: 760px) {
    display: none;
  }
`;

const MenuItems = (props) => {
    const {children, isLast, to = "/", isExternal, onClick, ...rest} = props
    return (
        <Text
            mb={{base: isLast ? 0 : 0, sm: 0}}
            mr={{base: 0, sm: isLast ? 0 : 8}}
            display="block"
            fontSize="0.8rem"
            color="#d7d7d7"
            {...rest}
        >
            {isExternal ?
                <Link
                    href={to}
                    isExternal
                    _focus={{outline: 'none'}}
                    onClick={onClick}
                    _hover={{textDecoration: 'underline'}}
                >
                    {children}
                </Link> :
                <Link href={to} _focus={{outline: 'none'}} onClick={onClick} style={{textDecoration: 'none'}}>{children}</Link>}

        </Text>
    );
}

const DesktopMenu = (props) => {
  const {isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1} = useDisclosure();
  const {isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2} = useDisclosure();
  const {isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3} = useDisclosure();
  const {isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4} = useDisclosure();

  return (
      <DisplayMenu>
        <div onMouseOver={onOpen1} onMouseLeave={onClose1}>
            <Menu isOpen={isOpen1}>
                <MenuButton fontSize="0.8rem" color="#d7d7d7">
                    Product
                </MenuButton>
                <MenuList minW="50px" bg="var(--components-primary-purple)" border="transparent" boxShadow="0px 10px 30px 0px rgba(187, 187, 187, 0.2)">
                    <MenuItems pl="14px" to="https://app.subseaprotocol.com" isExternal>
                       App
                    </MenuItems>
                </MenuList>
            </Menu>
        </div>
        <div onMouseOver={onOpen2} onMouseLeave={onClose2}>
            <Menu isOpen={isOpen2}>
                <MenuButton fontSize="0.8rem" color="#d7d7d7">
                    Developers
                </MenuButton>
                <MenuList minW="50px" bg="var(--components-primary-purple)" border="transparent" boxShadow="0px 10px 30px 0px rgba(187, 187, 187, 0.4)">
                    <MenuItems pl="14px" pt="5px" pb="8px" to="https://docs.subseaprotocol.com" isExternal>
                        Documentation
                    </MenuItems>
                </MenuList>
            </Menu>
        </div>
        <div onMouseOver={onOpen3} onMouseLeave={onClose3}>
            <Menu isOpen={isOpen3}>
                <MenuButton fontSize="0.8rem" color="#d7d7d7">
                    Community
                </MenuButton>
                <MenuList minW="50px" bg="var(--components-primary-purple)" border="transparent" boxShadow="0px 10px 30px 0px rgba(187, 187, 187, 0.4)">
                    <MenuItems pl="14px" pt="5px" pb="8px" to="https://twitter.com/SubseaProtocol" isExternal>
                        Twitter
                    </MenuItems>
                    <MenuItems pl="14px" pt="5px" pb="8px" to="https://discord.gg/WvW7r6Ekhe" isExternal>
                        Discord
                    </MenuItems>
                    <MenuItems pl="14px" pt="5px" pb="8px" to="https://t.me/SubseaProtocol" isExternal>
                        Telegram
                    </MenuItems>
                </MenuList>
            </Menu>
        </div>
        <div onMouseOver={onOpen4} onMouseLeave={onClose4}>
            <Menu isOpen={isOpen4}>
                <MenuButton fontSize="0.8rem" color="#d7d7d7">
                    Foundation
                </MenuButton>
                <MenuList minW="50px" bg="var(--components-primary-purple)" border="transparent" boxShadow="0px 10px 30px 0px rgba(187, 187, 187, 0.2)">
                    <RouteLink to="/about">
                        <MenuItems fontSize="0.8rem" as="a" pl="14px" pb="0px" _hover={{textDecoration: 'underline'}}>
                            About
                        </MenuItems>
                    </RouteLink>
                    <MenuItems pl="14px" pt="5px" pb="8px" to="https://medium.com/@SubseaProtocol" isExternal>
                        Blog
                    </MenuItems>
                </MenuList>
            </Menu>
        </div>
      </DisplayMenu>
  );
}

export default DesktopMenu;
