import { Text, Box, Heading, Center} from "@chakra-ui/react";
import React from "react";
import styled from 'styled-components';

const Page = styled(Center)`
  position: relative;
  flex-direction: column;
  margin: auto;
`;

const StyledHeading = styled(Heading)`
  font-size: 2rem !important;
  color: #d7d7d7;
  text-align: center;
  font-weight: 500 !important;
  margin-bottom: 4rem;
`;

const TextBox = styled(Box)`
  width: 950px;

  @media only screen and (max-width: 1365px) {
    width: 725px;
  }

  @media only screen and (max-width: 1023px) {
    width: 600px;
  }

  @media only screen and (max-width: 767px) {
    width: 320px;
  }
`;

const Copy = styled(Text)`
  font-size: 1.15rem;
  color: #bdbdbd;
  margin-top: 2rem;
`;

const About = () => {
  return (
    <Page>
      <Box marginTop="2rem" />
      <TextBox>
        <StyledHeading>The Open Protection Foundation</StyledHeading>
        <Copy>
          The Open Protection Foundation is a
          non-profit foundation dedicated to the decentralization,
          adoption, and security of the Subsea Protocol.
        </Copy>
        <Copy>
          Subsea is the world's leading risk management marketplace
          for digital assets that pioneered a completely automated,
          transparent, and impartial invariant detection mechanism to
          secure users against digital asset risks, hacks, and attacks.
        </Copy>
      </TextBox>
      <Box marginBottom="20rem" />
    </Page>
  );
}

export default About;
